import { useStaticQuery, graphql } from "gatsby"

export const useVoiceLocales = () => {
  const data = useStaticQuery(graphql`
    query QueryVoiceLocales {
      allQuakVoiceLocales {
        nodes {
          locale
          language
          languageName
          languageNativeName
        }
      }
    }
  `)

  return data.allQuakVoiceLocales.nodes
}
