import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useVoiceLocales } from "../../hooks/use-voice-locales"

const VoiceLocalesPage = () => {
  const voiceLocales = useVoiceLocales()

  return (
    <Layout title="Locales">
      <SEO title="Locales" keywords={[`sonos`, `audio`, `notifications`]} />

      <table>
        <thead>
          <tr>
            <th>Locale</th>
            <th>Language</th>
            <th>Name</th>
            <th>Native Name</th>
          </tr>
        </thead>
        <tbody>
          {voiceLocales.map((locale, index) => (
            <tr key={index}>
              <td>
                <code>{locale.locale}</code>
              </td>
              <td>
                <code>{locale.language}</code>
              </td>
              <td>{locale.languageName}</td>
              <td>{locale.languageNativeName}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        List of all <Link to="/voices/">voices</Link> and{" "}
        <Link to="/voices/languages/">languages</Link> where voices are
        available.
      </p>
    </Layout>
  )
}

export default VoiceLocalesPage
